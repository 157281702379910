<template>
  <div>
    <Recommendation :orderStatus="6"></Recommendation>
  </div>
</template>

<script>
import Recommendation from '@/components/Recommendation.vue'

export default {
  components: {
    Recommendation
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang='less'>
</style>
